import React from 'react';
import data from "./data";
import "./services.css";
import Card from './../../components/Card';
import csharp from "../../assets/csharp.jpg"
import Projects from './../portfolio/Projects';


const Services = () => {
  return (
    <section id='services'> 
    <div>
</div>
    <h2>Skills</h2>
    <p className='skillabs'>Three years of working in computer labs and building my own projects have allowed me to develop the following skills</p>

    <div className="container services__container" data-aos="fade-up">


    {
      data.map(item =>(
        <Card key={item.id} className="services light">
        <div className="services__icon" >{item.icon} </div>
         <div className="services__details">
          <h4>{item.title}</h4>
          <p>{item.desc}</p>
         </div>


          </Card>
      ))
    }
    </div>

     </section>
  );
}

export default Services;