
import AboutImage from "../../assets/SalifoFoto.jpg"
import data from "./data";
import Card from "../../components/Card";
import CV from "../../assets/Salifo-BanceCV-2023.pdf";
import './about.css';
import React, { Component }  from 'react';
import {AiOutlineCloudDownload} from "react-icons/ai";


const About = () => {
  return (
   <section id="about" data-aos="fade-in">
 <div className="container about__container">
<div className="about__left">

 <div className="about__portrait">
  <img src={AboutImage} alt="Salifo About image" className="aboutFoto" />
 </div>

</div>


<div className="about__right">

<h2>About me</h2>

<p>

<span className="Hey">Hey I'm Salifo</span> <br />
Here you can find more information about me
<br />
I'm a third year Computer Science student at <span>Manchester
 Metropolitan University </span>
I love technology and I have strong desire to make a
positive impact within the Technology industry.</p>
 <p>

I'm a focused and motivated person with good interpersonal and
 communication skills
and able to follow clear instructins, whether written or spoken
can speak English and Italian fluently.
<br />
<span className="workDesc">
I have solid foundation in Computer Science principles and two 
years experience of coding in the University labs along with two years
 of customer service experience in my current part-time job.
 <br />
 In the last 3 year I gained practical Experience and 
 knowledge in the University labs covering subjects such
  as: programming, Computer networks, Data Structure and 
  Algorithms... and more please check 
 my skills section for details or download my CV below
</span>
<br />
I have a strong work ethic, also I'm a team player who can work 
unsupervised or using initiative and can
remain calm under pressure and can organise tasks to ensure
deadlines are met on time.

I have hight level of accurancy and practical IT /Software skills
with a willingness to undertake further learning and training. </p>

I am a sports enthusiast with a particular passion for football.
When I was younger, I had the privilege of spending six years playing 
football in a
professional academy in Italy. Today, 
I enjoy football through friendly matches, also I like
running this help me to have an active and healthy lifestyle.


 <a href={CV} download className="btn primary"> <AiOutlineCloudDownload/> Download CV</a>

<div className="about__cards">
  {
    data.map(item => (
   <Card key={item.id} className="about__card">
    <span className= "about__card-icon" >{item.icon}</span>
    <h5>{item.title}</h5>
    <small>{item.desc}</small>
   </Card>
    ))
  }
</div>

</div>

 </div>



   </section>
  );
}

export default About;