import Image1 from '../../assets/Simone1.jpg'
import Image2 from '../../assets/Datas.jpg'
import Image3 from '../../assets/Ethical-Hacking1.jpg'
import Image4 from '../../assets/DotNetApi.jpg'
import Image5 from '../../assets/MyNetwork1.jpg'



const data = [
{
id: 4,
category: 'New Project',
image: Image4,
title: "DotNetApi+SQL+AZURE",
desc: "DotNet API developed using C# EF SQL deployed on Azure ",
demo: 'https://salidb1.azurewebsites.net/test',
github: 'https://github.com/salif9/DotNetApi.git'
},
{

id: 2,
category: 'Old Project',
image: Image2,
title: "C# Data Structures and Algorithms",
desc: "Demo about fundamentals data structures and Algorithms.",
demo: 'https://studio.youtube.com/playlist/PL-nhh9fg6fs73QXFGH4iOasm_9rdhz81J/videos',
github: 'https://github.com/egattor'
},
{
id: 1,
category: 'New Project',
image: Image1,
title: "Simple Simon Game",
desc: "I developed Simon's game using HTML-CSS-JS",
demo: 'https://salif9.github.io/MySimonGame/',
github: 'https://github.com/salif9/MySimonGame'
},

{
id: 3,
category: 'New Project',
image: Image3,
title: "Ethical-Hacking fundamentals",
desc: "Broken User Authentication Example demo!",
demo: 'https://www.youtube.com/watch?v=GfXjIB54T1Q',
github: 'https://github.com/egattor'
},
{
id: 5,
category: 'Old Project',
image: Image5,
title: "Computer Networking",
desc: " Build a  Local Area Network (LAN) using simulator software such Packet Tracer  ",
demo: "https://www.youtube.com/watch?v=ay6qbGzblGw",
github: ''
},

]
export default data;