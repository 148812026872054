import {FaAward} from "react-icons/fa";
import {TbBooks} from "react-icons/tb";
import {BiHappyHeartEyes} from "react-icons/bi";

const data =[
    // {id:1,icon: <FaAward/>, title: "Experience", desc: "3+ Years Working"},
    // {id:2,icon: <TbBooks/>, title: "Project", desc: "180+ Completed"},

    ]


export default data;
    