import { useEffect } from "react";
//import HeaderImage from "../../assets/salif2.jpg"
import video22 from "../../assets/space.mp4";






import AOS from "aos";
import data from "./data";
import React from 'react';
import "aos/dist/aos.css";
import "./header.css";

const Header= ()=> {
  useEffect(() =>{
  AOS.init({duration:3000})  /**SCROLLING EFFECT  */
  },[])


  return (
    
 <header id="header">
   <div className="main">
    <video src={video22} autoPlay loop muted></video>
  </div>
   <div className="container header__container">

    {/* <div className="header__profile" data-aos="flip-up" > 
    { <img className="salifoPicture" src={HeaderImage} alt="Salifo Picture" /> }
      </div> */}

      {/* <div className="Mynamediv">  <h3 data-aos="fade-up" className="Mynamediv" >Hi I'm Salifo Bance</h3> </div> */}

     <div className="textInHeader">
   
      <p id="ttt"  data-aos="fade-up" className="headetText ultra" >Hi there!
      I'm <span data-aos="fade-up" className="Mynamediv"  >  Salifo Bance </span>
      I'm currently studying Computer Science at Manchester Metropolitan University.
      
      </p>
     </div>

      <div className="header__cta" data-aos="fade-up">
         <a href="#contact" className="btn primary" data-aos="fade-left" >Let's Talk</a>
         <a href="#portfolio" className="btn light" data-aos="fade-right"> My work</a>
      </div>
    <div className="header__socials">
      {
       data.map(item => <a key={item.id} href={item.link} target="_blank" rel="noopener nreferrer" >{item.icon}</a> )
      }
    </div>
  </div>
 </header>
  
  )
}

export default Header;