import React from 'react';
import Logo from "../../assets/logo-no-background.png";
import data from "./data";
// import {IoIosColorPalette} from "react-icons/io"
import "./navbar.css";

function Navbar() {
  return (
    <nav>
      <div className="container  nav__container" >
      <a href="index.html" className="nav__logo">
        <img src={Logo} alt="Salifo Bance" />
      </a>

      <ul className="nav__menu" >
       {
        data.map(item => <li key={item.id} ><a href={item.link}> {item.title}</a> </li>)
       }
      </ul>
      {/* 5 */}
      </div>
    </nav>
  )
}

export default Navbar