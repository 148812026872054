import React from 'react';

import {SiAdobexd} from "react-icons/si";
import {RiReactjsFill} from "react-icons/ri";
import {FaServer} from "react-icons/fa";
import {AiFillAppstore} from "react-icons/ai";
import bell from "../../assets/csharp.jpg"
import jsPic from "../../assets/JS.png"
import NET from "../../assets/NET.jpg"
import sql from "../../assets/sqllls.jpg"
import html1 from "..//../assets/THML.jpg"
import css1 from "..//../assets/CSS.jpg"
import Network1 from "..//../assets/Network.jpg"
import ai1 from "..//../assets/AI.jpg"
import EthicalHacking from "..//../assets/Ethical-Hacking.jpg"





const data=[

{
    id:1, icon: <img src={bell} className="skillsPic" alt="" /> ,title: "C# Data Structors/Algorithms", img :  "."
},

{
    id:2, icon: <img src={jsPic}  className="skillsPic"  alt=""/>,title: "JS", desc: ""
},

{
    id:3, icon: <img src={sql} className="skillsPic"  alt="" />,title: "SQL", desc: ""
},

{
    id:4, icon:<img src={NET} className="skillsPic" alt=""  /> ,title: "DotNet Core", desc: ""
},


{
    id:5, icon:<img src={html1} className="skillsPic" alt=""  /> ,title: "HTML", desc: ""
},

{
    id:6, icon:<img src={css1} className="skillsPic" alt=""  /> ,title: "CSS", desc: ""
},

{
    id:7, icon:<img src={ Network1} className="skillsPic" alt=""  /> ,title: "Computer Networks", desc: ""
},
{
    id:8, icon:<img src={EthicalHacking} className="skillsPic" alt=""  /> ,title: "Ethical-Hacking fundamentals", desc: ""
},

{
    id:9, icon:<img src={ai1} className="skillsPic" alt=""  /> ,title: "Artificial Inteligence", desc: ""
},

];

export default data;
