import React from 'react';
import {AiOutlineInstagram} from "react-icons/ai";
import {AiOutlineTwitter} from "react-icons/ai";
import {AiFillGithub} from "react-icons/ai";
import {AiOutlineWhatsApp} from "react-icons/ai";


const data =[
    {id:1,link: "https://www.instagram.com/salifo_bance/", icon: <AiOutlineInstagram/>},
    {id:1,link: "https://twitter.com/home", icon: <AiOutlineTwitter/>},
    {id:1,link: "https://github.com/salif9", icon: <AiFillGithub/>},
    ];

    export default data;