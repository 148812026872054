import React from 'react';
import Navbar from './sections/navbar/Navbar';
import Header from './sections/header/Header'
import Services from './sections/services/Services';
import Portfolio from './sections/portfolio/Portfolio';
//import Testimonials from './sections/testimonials/Testimonials';
// import FAQs from './sections/faqs/FAQs';
import Contact from './sections/contact/contac';
import Footer from './sections/footer/Footer';
import FloatingNav from './sections/floating-nav/FloatingNav';
import About from './sections/about/About';

//import Theme from "./theme/Theme"
//import {useThemeContext} from "./context/theme-context"
//import { useRef,useState,useEffect } from 'react';


 const App = () => {
  //const mainRef = useRef()
  //const {themeState} = useThemeContext();
  //console.log(mainRef.current.getBoudingClientRect());
  return (
  <main>
 <Navbar/>
 <Header/>
 <About/>
 <Services/>
 <Portfolio/>
 {/* <FAQs/> */}
 <Contact/>
 <Footer/>
 <FloatingNav/>



  </main>
  )
}
export default App;